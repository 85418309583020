<template>
  <main class="table-font ">
    <TailwindDataTable
      :loading="isLoading"
      :tableData="accounts"
      :renderedItems="renderedItems"
      title="VFD Loan Sweep"
      @search="search = $event"
      :params="payload"
      @update="updateData"
      :cycle="cycle"
    >
      <template v-slot:extra="{ slotData }">
        <button
          @click="toggleModal(slotData)"
          disabled
          class="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-gray-900 text-white hover:bg-primary/90 h-10 py-2 px-4 rounded-md"
        >
          Sweep
        </button>
      </template>
    </TailwindDataTable>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import Loading from './general-components/Loading.vue'
import Modal from '../components/Modal.vue'
import { debounce } from '../utils/helper'

export default {
  name: 'Loans',
  data() {
    return {
      isLoading: false,
      renderedItems: [
        {
          name: 'Borrower Name',
          key: 'borrower_name',
        },
        {
          name: 'Borrower Phone',
          key: 'borrower_phone',
        },
        {
          name: 'Business Name',
          key: 'business_name',
        },
        {
          name: 'Tenor',
          key: 'tenor',
        },
        {
          name: 'Account Number',
          key: 'repayment_fixed_account_number',
        },
        {
          name: 'Amount Outstanding',
          key: 'loan_amount_outstanding',
        },
      ],
      search: '',
      moment,
      accounts: [],
      openModal: false,
      currentLoan: {},
      buttonLoad: false,
      successModal: false,
      payload: {
        search: '',
        pageNumber: '1',
        pageSize: '100',
        hasNextPage: false,
        hasPrevPage: false,
        totalCount: '',
        totalPages: '',
      },
      cycle: 0,
    }
  },
  components: {
    Loading,
    Modal,
  },
  computed: {
    ...mapGetters(['GET_LOANS']),
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
  },
  watch: {
    search: debounce(function(newVal) {
      this.payload.search = newVal.toLowerCase()
      this.payload.pageNumber = '1'
      this.payload.pageSize = '100'
      this.payload.hasNextPage = false
      this.payload.hasPrevPage = false
      this.payload.totalCount = ''
      this.payload.totalPages = ''
      this.fetchVfd()
    }, 3000),
  },
  methods: {
    debounce(fn, delay) {
      var timeoutID = null
      return function() {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function() {
          fn.apply(that, args)
        }, delay)
      }
    },
    toggleModal(loan) {
      this.currentLoan = loan
      this.openModal = true
    },
    async fetchVfd() {
      this.isLoading = true
      try {
        const body = this.payload

        let res = await this.$store.dispatch('GET_ALL_VFD', body)
        console.log(res, 'res')
        this.accounts = res.data.data
        let {
          hasNextPage,
          hasPrevPage,
          totalCount,
          totalPages,
          pageNumber,
          pageSize,
        } = res.data.meta
        this.payload = {
          ...this.payload,
          hasNextPage,
          hasPrevPage,
          totalCount: totalCount.toString(),
          totalPages: totalPages.toString(),
          pageNumber: pageNumber.toString(),
          pageSize: pageSize.toString(),
        }
        this.isLoading = false
      } catch (error) {
        console.log(error, 'error')
      }

      this.isLoading = false
    },
    async disburse() {
      this.buttonLoad = true
      try {
        let res = await this.$store.dispatch('DISBURSE_VFD', {
          AccountNo: this.currentLoan.repayment_fixed_account_number,
          LoanAmount: this.currentLoan.loan_amount,
          Duration: this.currentLoan.tenor,
        })
        if (res) {
          this.successModal = true
          this.openModal = false
        }
        this.buttonLoad = false
        this.fetchVfd()
      } catch (error) {
        console.log(error, 'error')
      }

      this.buttonLoad = false
    },
    updateData(payload) {
      let data = payload.map((x) => x.value)
      this.renderedItems = data
      this.cycle += 1
    },
  },
  async mounted() {
    await this.fetchVfd()
  },
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}

table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}

table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}

table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}

table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}

table tr td {
  background: #f0f6fd;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
